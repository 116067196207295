import { Component, Vue } from 'vue-property-decorator';

@Component<Dashboard>({})
export default class Dashboard extends Vue {
  public $pageTitle = 'Dashboard';

  protected isLoading = true;

  protected mounted() {
    this.initBreadcrumb();
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Dashboard' },
        ],
      });
  }
}
